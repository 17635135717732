import * as React from 'react';

import { useRouter } from 'next/router';

import { AppBar } from '@material-ui/core';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import SvgIcon from '@material-ui/core/SvgIcon';
import { getMe } from '../../api/user';
import { getAttributeFromUserType } from '@utils';

import { useFormatMessage } from 'evl-ui-lib';
import { EvlBox, EvlDivider, EvlList, EvlToolbar, EvlTypography } from 'evl-ui-components';
import Link from 'next/link';

import Route from '@components/link';

import useStyles from './HeaderJSS';
import LanguageSelector from '@components/LanguageSelector/LanguageSelector';

type Props = {
  disableMenuDrawer?: boolean;
  disableBackButton?: boolean;
  title?: React.ReactNode;
  onBack?: Function;
  onScroll?: any;
  fullScreen?: boolean;
  footer?: React.ReactNode;
  styleProps?: any;
};
declare global {
  interface Window {
    headerColorType: string;
  }
}

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const Header: React.FunctionComponent<Props> = ({
  onBack,
  disableMenuDrawer,
  disableBackButton,
  title,
  onScroll,
  children,
  footer,
  styleProps,
}) => {
  const classes = useStyles(styleProps);
  const translate = useFormatMessage();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
  const [availableLangs, setAvailableLangs] = React.useState<string[]>([]);
  const [name, setName] = React.useState<string>('');
  const router = useRouter();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const me = await getMe();
        let langStr = getAttributeFromUserType({
          userType: me,
          attrName: 'locale',
        });
        if(langStr){
          setAvailableLangs(langStr.split(","));
        }
        setName(
          getAttributeFromUserType({
            userType: me,
            attrName: 'preferred_username',
          }),
        );
      } catch (error) {}
    };
    fetchData();
  }, []);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  const handleClick = () => {
    document.getElementById('feedbackButton')!.click();
  };

  const list = () => (
    <EvlBox
      //@ts-ignore
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <div className={classes.welcomeContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="40"
          viewBox="0 0 36 40"
          fill="none"
          className={classes.welcomeImage}
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26.5117 8.1489C29.4763 8.15555 32.4411 8.16221 35.406 8.15444V0H0.131224C0.131224 0.581002 0.146093 1.09473 0.159637 1.56269C0.186013 2.47397 0.207364 3.21167 0.104098 3.93453C-0.364782 7.24387 0.702031 8.42626 4.28999 8.32433C11.6919 8.11562 19.1008 8.13226 26.5117 8.1489ZM9.65461 22.4314H35.4023V14.277C34.9817 14.2276 34.5605 14.1654 34.139 14.1031C33.2257 13.9682 32.3111 13.8331 31.3998 13.8285C30.4081 13.8217 29.4165 13.8145 28.4248 13.8072C21.4864 13.7567 14.5486 13.7062 7.616 13.8013C3.35256 13.8627 1.42762 13.3934 0.605562 14.1394C-0.210194 14.8797 0.0601027 16.8167 0.209026 21.6567C0.596362 34.0243 9.86529 41.6419 21.5737 39.6985C21.6621 33.9428 21.3562 33.4943 16.2801 32.0265C11.9446 30.7693 9.57306 27.4328 9.65461 22.4314Z"
            fill="#153947"
          />
        </svg>
        <div>
          <EvlTypography variant="body2" className={classes.navTitle}>
            {translate('nav.welcome')}
          </EvlTypography>
          <EvlTypography variant="body2" className={classes.navSubtitle}>
            {name}
          </EvlTypography>
        </div>
      </div>
      <EvlDivider />
      <EvlList className={classes.list} onClick={toggleDrawer(false)}>
        <Route link="/vault" label={translate('vault.title')} icon="/static/images/drawer/ic_vault_dark.svg" />
        <Route
          link="/transfers"
          label={translate('transfers.title')}
          icon="/static/images/drawer/ic_transfer_dark.svg"
        />
        <Route link="/settings" label={translate('settings.title')} icon="/static/images/drawer/ic_settings_dark.svg" />
      </EvlList>
      <EvlDivider />
      <button data-trove-survey="bcwRFFvnKS" id="feedbackButton" hidden></button>
      <div className={classes.feedbackContainer} onClick={toggleDrawer(false)}>
        <button className={classes.feedbackButton} onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            className={classes.feedbackSVG}
          >
            <path
              d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM11 9H9V3H11V9ZM11 13H9V11H11V13Z"
              fill="#062734"
            />
          </svg>
          <EvlTypography variant="body2" className={classes.feedbackText}>
            {translate('nav.feedback')}
          </EvlTypography>
        </button>
      </div>
    </EvlBox>
  );

  // const [headerColor, setHeaderColor] = React.useState<PropTypes.Color | 'transparent'>(
  //   !disableMenuDrawer ? 'default' : 'transparent',
  // );

  // const onScrollCallback = React.useCallback(() => {
  //   onScroll();
  // }, []);

  // function onScroll() {
  //   if (window.headerColorType !== 'default' && window.scrollY > 30) {
  //     window.headerColorType = 'default';
  //     setHeaderColor('default');
  //   }
  //   if (window.headerColorType !== 'transparent' && window.scrollY === 0) {
  //     window.headerColorType = 'transparent';
  //     setHeaderColor('transparent');
  //   }
  // }

  // React.useEffect(() => {
  //   if (disableMenuDrawer) {
  //     window.addEventListener('scroll', onScrollCallback);
  //   }
  //   return () => {
  //     if (disableMenuDrawer) {
  //       window.removeEventListener('scroll', onScrollCallback);
  //     }
  //   };
  // }, [disableMenuDrawer]);

  return (
    <>
      <AppBar position="sticky" color="primary" className={classes.appBar}>
        <EvlToolbar disableGutters>
          {(['left'] as Anchor[]).map(anchor => (
            <React.Fragment key={anchor}>
              {!disableMenuDrawer ? (
                <>
                  {/* drawer menu */}
                  <IconButton className={classes.drawerButton} aria-label="open drawer" onClick={toggleDrawer(true)}>
                    <MenuIcon />
                  </IconButton>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={isDrawerOpen}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    className={classes.drawer}
                  >
                    {list()}
                  </SwipeableDrawer>
                </>
              ) : disableBackButton ? (
                <div className={classes.blankAction} />
              ) : (
                <IconButton
                  arial-label="back"
                  onClick={() => (onBack ? onBack() : router.back())}
                  classes={{
                    root: classes.backButton,
                  }}
                >
                  <SvgIcon style={{ color: 'white' }}>
                    <path d="M11.4841 19.7759C11.8256 19.4344 11.8256 18.9221 11.4841 18.5806L2.09242 10L11.4841 1.41942C11.8256 1.07791 11.8256 0.565635 11.4841 0.22412C11.1853 -0.0747065 10.6303 -0.0747066 10.2888 0.224119L0.256776 9.35966C0.0860191 9.53042 0.000640441 9.78655 0.000640432 10C0.000640423 10.2134 0.0433288 10.4696 0.256776 10.6403L10.2888 19.7759C10.6303 20.0747 11.1853 20.0747 11.4841 19.7759Z" />
                  </SvgIcon>
                </IconButton>
              )}
              {title ? (
                <EvlTypography className={classes.title}>{title}</EvlTypography>
              ) : (
                // identify-brand logo
                <div className={classes.identifyLogo}>
                  <Link href="/">
                    <img src="/static/images/identifye-logo.svg" alt="IdentifyE" className={classes.logo} />
                  </Link>
                </div>
              )}

              {styleProps?.rightActionTool ? (
                <Button
                  classes={{ root: classes.rightActionTool }}
                  type="button"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  disableElevation
                />
              ) : (
                availableLangs && availableLangs.length > 0 ?
                  <LanguageSelector availableLangs={availableLangs}/>
                  :
                  <div className={classes.blankAction} />
              )}
            </React.Fragment>
          ))}
        </EvlToolbar>
      </AppBar>
      {isDrawerOpen && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          className={classes.cross}
          onClick={toggleDrawer(false)}
        >
          <path
            d="M15.6382 0.361809C15.1558 -0.120603 14.3518 -0.120603 13.8693 0.361809L8 6.23115L2.17085 0.361809C1.68844 -0.120603 0.884422 -0.120603 0.40201 0.361809C-0.0804018 0.844221 -0.0804018 1.64824 0.40201 2.13065L6.23115 8L0.361809 13.8291C-0.120603 14.3116 -0.120603 15.1156 0.361809 15.598C0.603015 15.8392 0.924623 15.9598 1.24623 15.9598C1.56784 15.9598 1.88945 15.8392 2.13065 15.598L8 9.76884L13.8291 15.6382C14.0704 15.8794 14.392 16 14.7136 16C15.0352 16 15.3568 15.8794 15.598 15.6382C16.0804 15.1558 16.0804 14.3518 15.598 13.8693L9.76884 8L15.6382 2.17085C16.1206 1.64824 16.1206 0.884422 15.6382 0.361809Z"
            fill="white"
          />
        </svg>
      )}
      <EvlBox className={classes.container} onScroll={onScroll}>
        {children}
      </EvlBox>
      {footer && <EvlBox className={classes.footer}> {footer}</EvlBox>}
    </>
  );
};

export default Header;
