import * as React from 'react';
import { EvlLink, EvlListItem, EvlBox } from 'evl-ui-components';
import { RBAPolicy, AccessLevel } from 'evl-ui-lib';
import useStyles from './LinkJSS';
import classNames from 'classnames';
import Link from 'next/link';
import { UrlObject } from 'url';
import { useRouter } from 'next/router';
import { isObject, isEqual } from 'lodash';

interface RouteLabelProps {
  link?: string | UrlObject;
  label: string;
  icon: string;
  as?: any;
  onClick?: () => void;
}
interface RouteProps extends RouteLabelProps {
  accessLevel?: AccessLevel[];
  children?: React.ReactNode;
}

const RouteLabel: React.FC<RouteLabelProps> = ({ label, icon, link, onClick }) => {
  const router = useRouter();
  const classes = useStyles();
  return (
    <EvlLink
      className={classNames(classes.appLink, {
        [classes.activeLink]:
          (!isObject(link) && router.pathname === link) ||
          isEqual(link, { pathname: router.pathname, query: router.query }),
      })}
      color="primary"
      onClick={onClick}
    >
      <EvlBox component="figure" className={classes.drawerIcons}>
        <img src={icon} alt="" />
      </EvlBox>
      {label}
    </EvlLink>
  );
};

const Route: React.FC<RouteProps> = ({ link, label, accessLevel, children, as, icon, onClick }) => {
  const classes = useStyles();
  return (
    <RBAPolicy accessLevel={accessLevel}>
      <EvlListItem className={classes.listItem}>
        {(!!link && (
          <Link href={link} as={as}>
            <RouteLabel label={label} icon={icon} link={link} />
          </Link>
        )) || <RouteLabel label={label} icon={icon} onClick={onClick} />}
        {children}
      </EvlListItem>
    </RBAPolicy>
  );
};

export default Route;
