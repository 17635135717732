import { createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: theme.spacing(45),
    },
    textRight: {
      textAlign: 'right',
    },
    appLink: {
      fontSize: theme.spacing(1.6),
      fontWeight: 700,
      color: theme.palette.secondary.main,
      display: 'flex',
      padding: theme.spacing(1, 2.4),
    },
    activeLink: {
      color: theme.palette.secondary.dark,
      backgroundColor: '#EEF2F4',
      borderRadius: '4px',
    },
    m0: {
      margin: theme.spacing(0),
    },
    evlBrand: {
      backgroundColor: theme.palette.secondary.dark,
      '&:hover': {
        backgroundColor: theme.palette.grey.A700,
      },
    },
    brandFigure: {
      margin: theme.spacing(1.5, 0),
      maxHeight: theme.spacing(2.4),

      '& $img': {
        margin: `${theme.spacing(0)} auto`,
      },
    },
    listItem: {
      display: 'block',
      padding: theme.spacing(0),
    },
    drawerIcons: {
      margin: theme.spacing(0, 2.4, 0, 0),
      display: 'flex',

      '& $img': {
        width: theme.spacing(1.8),
      },
    },
    root: {
      display: 'flex',
    },
    drawerBtnGroup: {
      position: 'fixed',
      top: theme.spacing(7.1),
      left: theme.spacing(5.5),
      zIndex: 1,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      [theme.breakpoints.down('sm')]: {
        position: 'static',
        margin: theme.spacing(0, 2.7, 0, 0.7),
      },
    },
    drawerBtnGroupPosition: {
      left: drawerWidth - theme.spacing(1.5),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      backgroundColor: theme.palette.common.white,
      borderWidth: theme.spacing(0.1),
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      borderRadius: theme.shape.borderRadius * 2,
      width: theme.spacing(3),
      height: theme.spacing(3),
      minWidth: 'inherit',
      padding: theme.spacing(0),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      '& .MuiDrawer-paperAnchorLeft': {
        left: 'inherit',
        right: 'inherit',
      },

      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        flexShrink: 'inherit',
        flex: 'inherit',
        left: theme.spacing(-7),
        top: theme.spacing(6.4),
      },
    },
    drawerOpen: {
      width: drawerWidth,
      backgroundColor: theme.palette.primary.dark,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      [theme.breakpoints.down('sm')]: {
        left: theme.spacing(0),
        top: theme.spacing(6.4),
      },
    },
    drawerClose: {
      backgroundColor: theme.palette.primary.dark,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7),

      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7),
      },

      [theme.breakpoints.down('sm')]: {
        top: theme.spacing(6.4),
      },
    },
    profileBox: {
      display: 'flex',
      paddingLeft: theme.spacing(1.3),
      paddingRight: theme.spacing(1.3),
      alignItems: 'flex-start',
    },
    profileTitle: {
      width: '100%',
      whiteSpace: 'normal',
      wordBreak: 'break-all',
      paddingBottom: theme.spacing(0.5),
      lineHeight: `${theme.spacing(1.6)}px`,
      display: 'block',
    },
    resAppBar: {
      backgroundColor: theme.palette.primary.dark,
    },
    resToolbar: {
      padding: theme.spacing(0, 1.6),
      minHeight: theme.spacing(6.4),
    },

    resDrawerContainer: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(6.4),
      },
    },
  }),
);

export default useStyles;
