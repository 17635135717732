import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    LanguageSelector: {
      paddingRight: '10px',
      '@media (max-width: 480px)': {
        width: '180px',
        paddingRight: 0,
      }
    },
    languageSwitchLabel: {
      cursor: 'pointer',
      '@media (max-width: 480px)': {
        padding: '0'
      }
    },
    languageSwitch: {
      borderRadius: '10px',
      width: '37.5px !important',
      height: '20px !important',
      padding: '0 !important',
      float: 'right',
      
      '& .MuiSwitch-thumb': {
        backgroundColor: '#FFFFFF !important',
      },

      '& .MuiSwitch-track': {
        opacity: '1 !important',
        backgroundColor: '#BEBEBE !important',
      },

      '& > span': {
        top: '-7px',
        left: '-7px',
        backgroundColor: '#bebebe !important',
        opacity: '1 !important',

        '& > span': {
          '& > span': {
            width: '16.47px',
            height: '16.47px',
            boxShadow: 'none',
            borderRadius: '50%',
            backgroundColor: '#FFFFFF',
          },
        },
      }
    },
  }),
);

export default useStyles;
