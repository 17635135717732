import React from 'react';
import { EvlBox, EvlGrid } from 'evl-ui-components';
import useStyles from './LanguageSelectorJSS';
import { useFormatMessage, useLocale } from 'evl-ui-lib';
import { getMessages } from '@utils';
import getLocale from 'utils/getLocale';
import { Switch } from '@material-ui/core';

interface LanguageSelectorProps {
  pageCtx?: any;
  availableLangs: string[];
}
const LanguageSelector: React.FC<LanguageSelectorProps> = ({pageCtx, availableLangs}) => {
  
  const classes = useStyles();
  const translate = useFormatMessage();
  const {setLocale} = useLocale();
  const [selectedLocale, setSelectedLocale] = React.useState('en');
  const loadLangSelector = async (langsArr: any) => {
    const locale = await getLocale(pageCtx, langsArr);
    handleLangChange(locale);
  }
  React.useEffect(() => {
    if(availableLangs && availableLangs.length > 0){
      loadLangSelector(availableLangs);
    }
  }, [availableLangs])
  

  const handleLangChange = async (selectedLang: string) => {
    setSelectedLocale(selectedLang);
    const messages = await getMessages(selectedLang);
    window.localStorage.setItem('locale', selectedLang);
    await setLocale(selectedLang, messages);
  }
  return (<EvlBox className={classes.LanguageSelector}>
    <EvlGrid container alignItems="center" spacing={1}>
      <EvlGrid item className={classes.languageSwitchLabel} onClick={() => handleLangChange('en')}>{translate('language.english')}</EvlGrid>
      <EvlGrid item>
        <Switch disableRipple checked={selectedLocale === 'es'} className={classes.languageSwitch} onChange={(e) => handleLangChange(e.target.checked ? 'es': 'en')} />
      </EvlGrid>
      <EvlGrid item className={classes.languageSwitchLabel} onClick={() => handleLangChange('es')}>{translate('language.spanish')}</EvlGrid>
    </EvlGrid>
  </EvlBox>);
};

export default LanguageSelector;
